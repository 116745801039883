import(/* webpackMode: "eager", webpackExports: ["Root","Indicator"] */ "/opt/industriemeister_sbh/frontend/node_modules/@radix-ui/react-checkbox/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/node_modules/@radix-ui/react-label/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Root","Item","Indicator"] */ "/opt/industriemeister_sbh/frontend/node_modules/@radix-ui/react-radio-group/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Root","Track","Range","Thumb"] */ "/opt/industriemeister_sbh/frontend/node_modules/@radix-ui/react-slider/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/industriemeister_sbh/frontend/src/components/Form/Form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/industriemeister_sbh/frontend/src/components/Popover/Popover.tsx");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/src/components/Slider/Slider.tsx");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/src/icons/angle-right.svg");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/src/icons/file.svg");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/src/icons/point-hand.svg");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/src/icons/tooltip.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/industriemeister_sbh/frontend/src/streamfields/Accordions/Accordions.tsx");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/src/streamfields/EventFinder/EventFinder.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/industriemeister_sbh/frontend/src/streamfields/ProductPresentation/ProductPresentation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/industriemeister_sbh/frontend/src/streamfields/ReadMore/ReadMore.tsx");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/src/streamfields/Reviews/ReviewText.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/industriemeister_sbh/frontend/src/streamfields/TargetGroup/TargetGroup.tsx");
