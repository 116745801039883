'use client';

import AngleRight from '@icons/angle-right.svg';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { PropsWithChildren, ReactNode } from 'react';

import '@splidejs/react-splide/css/core';

type Props = {};

function Slider(props: PropsWithChildren<Props>) {
  const { children } = props;

  if (!Array.isArray(children)) return <div className='slider-image'>{children}</div>;

  return (
    <Splide
      hasTrack={false}
      options={{
        mediaQuery: 'min',
        type: 'loop',
        gap: '1rem',
        pagination: false,
        perPage: 1,
        breakpoints: {
          450: {
            perPage: 2,
          },
          /* 700: {
            perPage: 3,
          }, */
        },
      }}
    >
      <SplideTrack>
        {(children as ReactNode[]).map((child, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <SplideSlide key={index}>{child}</SplideSlide>
        ))}
      </SplideTrack>
      <div className='splide__arrows'>
        <button className='splide__arrow splide__arrow--prev'>
          <AngleRight width={56} height={56} />
        </button>
        <button className='splide__arrow splide__arrow--next'>
          <AngleRight width={56} height={56} />
        </button>
      </div>
    </Splide>
  );
}

export default Slider;
