'use client';

import { ReactNode, useEffect, useRef, useState } from 'react';

import RawHtml from '@components/RawHtml';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import clsx from 'clsx';
import { usePathname } from 'next/navigation';

type Props = {
  content: ReactNode | string;
  buttonText: ReactNode;
  ariaButtonText?: string;
  className?: string;
  containerClassName?: string;
  initialOpen?: boolean;
};

function Popover(props: Props) {
  const { content, buttonText, className, containerClassName, ariaButtonText, initialOpen } = props;
  const [open, setOpen] = useState(initialOpen ?? false);
  const pathname = usePathname();
  const initalRef = useRef(true);

  useEffect(() => {
    if (initalRef.current) {
      initalRef.current = false;
      return;
    }

    setOpen(false);
  }, [pathname]);

  return (
    <div className={clsx('popover', className, containerClassName)} id={className}>
      <PopoverPrimitive.Root open={open} onOpenChange={setOpen}>
        <PopoverPrimitive.Trigger
          className={clsx(className, 'popover__trigger')}
          aria-label={ariaButtonText}
        >
          {buttonText}
        </PopoverPrimitive.Trigger>
        <PopoverPrimitive.Content className={clsx(className, 'popover__content')} side='top'>
          <PopoverPrimitive.Close className={clsx(className, 'popover__close')}>
            &times;
          </PopoverPrimitive.Close>
          <PopoverPrimitive.Arrow className={clsx(className, 'popover__arrow')} />
          {typeof content === 'string' ? <RawHtml html={content} /> : content}
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Root>
    </div>
  );
}

export default Popover;
